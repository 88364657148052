$(document).ready(function() {

//Don't do this on landing page
	if( $('body#landingpage').length < 1 ) {

  // Check for click events on the navbar burger icon
	$(".navbar-burger").on("click", () => {
		$(".navbar-burger").toggleClass("is-active");
		$(".navbar-menu").toggleClass("is-active");
	});

	$(".flash-message-close").on("click", function() {
		$(".flash-message").slideUp( 200 );
		document.cookie = "flashClicked=true";
	});

// Cookie for Flash Message
	$( window ).on( 'load', ()=> {
		if( document.cookie.indexOf( 'flashClicked=true' ) === -1 ){
			$( '.flash-message' ).slideDown( 200 );
		}
	} );

	$("#main-nav").superfish({
		animation: { height: "show" },
		animationOut: { height: "hide" },
		cssArrows: false
	});

	$('.menu-item').on('mouseenter.superfish mouseleave.superfish', function(e){
		if( $( '.navbar-burger' ).is( ':visible' ) ){
			e.preventDefault();
			e.stopPropagation();
			e.stopImmediatePropagation();
			return false;
		}

	});


$(window).scroll(function(){
	var sticky = $('.has-fixed-header'),
		scroll = $(window).scrollTop();
  
	if (scroll > 90) sticky.addClass('fixed-header');
	else sticky.removeClass('fixed-header');
  });
}

//Do this on landing page
	objectFitImages();

});

