// Use for Google Maps API with key
// var map;
// var latLng = {lat: -34.397, lng: 150.644};
// function initMap() {
//   map = new google.maps.Map(document.getElementById('g-map'), {
//     center: latLng,
//     zoom: 15, 
//     disableDefaultUI: true
//   });

//   var marker = new google.maps.Marker({
//     position: latLng,
//     map: map,
//   });

// }

if (document.getElementById('g-map')) {
// Free Google Maps Embed
// create the iframe and with the required properties.
    var iframe = document.createElement("iframe");
    iframe.width = '100%';
    iframe.height = '100%';
    iframe.frameborder = 0;
    iframe.style.border = 0;
    iframe.setAttribute('allowFullScreen', '');
    document.getElementById('g-map').appendChild(iframe);
    
    // sample lat and long.
    //var lat = 30.527349;
    //var long = -97.689225;
    //var zoom = 16;
    //var addr = "2000+North+Mays+St.%2C+Ste+112%2C+Round+Rock%2C+TX+78664";
    
    // get the scale
    var mapScale = 591657550.500000 / Math.pow( 2, zoom+1);
    // get the base64 representation
    var base64 = btoa([lat,long]); // lazy "long,lat" formatting
	// console.log("base64",base64);
    // set the source of the iframe

    if( embed ){
        $(iframe).addClass("embededmap");
        iframe.src = embed;
    } else {
        iframe.src = 'https://www.google.com/maps/embed?pb=!1m7!1m2!1m1!1d' + mapScale + '!3m3!1m2!1s0!2z' + base64 + '!2s' + addr;
    }

    
} 