function slick_on_mobile(slider, settings){

      $(window).on('load resize', function() {
        if ( slider.css('display') === "flex" ) {
          if (slider.hasClass('slick-initialized')) {
            slider.slick('unslick');
          }
          return
        }
        if (!slider.hasClass('slick-initialized')) {
          return slider.slick(settings);
        } 
      }); 
}; 

/////// Fix body in background while modal is displayed
function fixPage(){
	const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
	const body = document.body;
	body.style.position = 'fixed';
	body.style.top = `-${scrollY}`;
}

/// Unfix body 
function releasePage(){
	const body = document.body;
	const scrollY = body.style.top;
	body.style.position = '';
	body.style.top = '';
	window.scrollTo(0, parseInt(scrollY || '0') * -1);
}

$( document ).ready( function(){

/// Add Default Slick Carousel
	$( '.pathway-carousel' ).slick(
		{
			dots: true,
			arrows: true,
			autoplay: true,
			autoplaySpeed: 5000
		}
	);

	function set_height(){
		var max_height = window.innerHeight * 0.7;
		$( '.photo-gallery-modal-image img' ).each( function(){
			console.log( "max height: " + max_height);
			console.log( $( this ).innerHeight() );
			console.log( $(this) );
			if( $( this ).innerHeight() > max_height ){
				$( this ).addClass( 'photo-fit-height' );
			} else {
				$( this ).removeClass( 'photo-fit-height' );
			}
		} );
	}

	$( window ).on( 'load resize', function(e){
		set_height();
	} );


	// Set Page Scroll Position
	  window.addEventListener('scroll', () => {
		document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
	  });
	
	//////Silder on the Modal Pop-Up
	  $('section').on('click', '.lightbox-slide-open', function(e){
	
		  e.preventDefault;
		  var slide = $(this).data('thumbIndex');
		  var target = $(this).data('modalTarget');
		  $(target).addClass('is-active')
		  .children('.modal-content-slider')
		  .slick({
			prevArrow: '.lightbox-arrow-prev',
			nextArrow: '.lightbox-arrow-next',
		  })
			.slick('slickGoTo', slide, true);
			
			$("html").addClass("is-clipped");
	
	  });
	
	//Close Modal
	  $('section').on('click', '.lightbox-slide-close', function(e){
	
		$('.lightbox-modal').removeClass('is-active');
		// releasePage();
		$("html").removeClass("is-clipped");
		$('.modal-content-slider').slick('unslick');
	
	  });

});