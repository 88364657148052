$(function () {
	function setLabel(text) {
		$('.newsletter-form-message').fadeIn( 200 ).html(text);
	}

	function validateEmail(email) {
		// var regex = /^[a-z0-9\.\_%+-]+@@[a-z0-9\.\-]+\.[a-z]{2,4}$/i;
		var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return regex.test(String(email).toLowerCase());
	}

	$('.newsletter-signup-button').on('click', function (evt) {
		evt.preventDefault();

		var email = $( this ).prev( '.newsletter-signup-input' ).val();
		if (!validateEmail(email)) {
			setLabel('Please enter a valid email.');
			return;
		}
		
		var bodyData = 'email=' + email;
		$.ajax('/api/form/mailinglist/send',{
			'data': bodyData, 
			'type': 'POST',
			'processData': false,
			'contentType': 'text' 
		}).done(function (result) {
			if (result.success) {
				$( '.newsletter-form, .newsletter-form-message' ).hide();
				$('.newsletter-success').show();
			} else {
				setLabel(result.error.email);
			}
		})
		.fail(function (xhr) {
			setLabel('There was an error submitting your email.');
		});
	});

});