$( document ).ready(function() {

  /////Slider on Mobile Settings
  var standard_settings = {
	dots: true,
	arrows: true 
  }
  
  var center_variable_settings ={ 
	  dots: true,
	  arrows: true,
	  centerMode: true,
	  slidesToShow: 1,
	  variableWidth: true
  }

  var center_padding_settings = {
	  dots: true,
	  arrows: true,
	  centerMode: true,
	  centerPadding: '30px'
  }

  slick_on_mobile( $( '.promo-grid-container' ), center_padding_settings );
  slick_on_mobile( $( '.referring-vets-container' ), center_padding_settings );
  slick_on_mobile( $( '.teams-card-grid-container' ), standard_settings );
  slick_on_mobile( $( '.leadership-card-grid-container' ), standard_settings );

}); ///End Document Ready
